<template>
  <div class="area-level">
    <x-table
      :columns="table.columns"
      :data="table.data"
      :loading="table.loading"
      :config="config"
      @add="add"
      @page-size-change="pageSizeChange"
      @page-change="pageChange"
    ></x-table>

    <Modal
      v-model="modal.show"
      :title="modal.title"
      @on-visible-change="modalShow"
    >
      <Form ref="form" :model="form" :rules="rules" :label-width="100">
        <FormItem label="地力等级" prop="level">
          <Input v-model="form.level"></Input>
        </FormItem>
        <FormItem label="等级名称" prop="levelName">
          <Input v-model="form.levelName"></Input>
        </FormItem>
        <FormItem label="施肥占比" prop="manureFertilizationPercentage">
          <Input v-model="form.manureFertilizationPercentage">
            <span slot="append">%</span>
          </Input>
        </FormItem>
        <FormItem label="简介" prop="content">
          <Input v-model="form.content" type="textarea" :rows="3"></Input>
        </FormItem>
      </Form>
      <p slot="footer" style="text-align: center">
        <Button type="primary" :loading="modal.submitLoading" @click="submit"
          >提交</Button
        >
        <Button @click="() => (modal.show = false)">取消</Button>
      </p>
    </Modal>
  </div>
</template>

<script>
import CU from "@/common/util";
export default {
  name: "",
  data() {
    return {
      table: {
        columns: [
          {
            title: "地力等级",
            minWidth: 100,
            key: "level",
          },
          {
            title: "等级名称",
            minWidth: 100,
            key: "levelName",
          },
          {
            title: "施肥占比",
            minWidth: 100,
            key: "manureFertilizationPercentage",
          },
          {
            title: "简介",
            minWidth: 100,
            key: "content",
          },
          {
            title: "操作",
            width: 100,
            render: (h, { row }) => {
              return (
                <div>
                  <a onClick={() => this.edit(row)}>编辑</a>
                </div>
              );
            },
          },
        ],
        data: [],
        loading: false,
      },
      page: {
        pageNo: 1,
        pageSize: 10,
        total: 0,
      },
      modal: {
        show: false,
        title: "",
        submitLoading: false,
      },
      form: {
        id: "",
        level: "",
        levelName: "",
        manureFertilizationPercentage: "",
        content: "",
      },
      rules: {
        level: [{ required: true, message: "请填写地力等级" }],
        levelName: [{ required: true, message: "请填写等级名称" }],
        manureFertilizationPercentage: [
          { required: true, message: "请填写施肥占比" },
          {
            validator(rule, value, callback) {
              if (!CU.validateNumber(value)) {
                callback(rule.message);
                return;
              }
              callback();
            },
            message: "请填写正确的施肥占比",
          },
        ],
      },
    };
  },
  computed: {
    config() {
      let config = {
        filter: {
          add: {
            addBtnName: "新增地力等级",
          },
        },
        page: this.page,
      };
      return config;
    },
  },
  methods: {
    add() {
      this.modal = {
        show: true,
        title: "新增地力等级",
        submitLoading: false,
      };
    },
    pageChange(pageNo) {
      this.page.pageNo = pageNo;
      this.getList();
    },
    pageSizeChange(pageSize) {
      this.page.pageSize = pageSize
      this.getList()
    },

    getList() {
      this.table.loading = true;
      this.$post(this.$api.AREA_LEVEL.LIST, {
        pageNo: this.page.pageNo,
        pageSize: this.page.pageSize,
      })
        .then((res) => {
          this.table.data = res.list;
          this.page.total = +res.total;
        })
        .finally(() => {
          this.table.loading = false;
        });
    },

    edit(row) {
      for (let key in this.form) {
        this.form[key] = row[key];
      }
      this.modal = {
        show: true,
        title: "编辑地力等级",
        submitLoading: false,
      };
    },

    modalShow(visible) {
      if (visible) return;
      this.form = {
        id: "",
        level: "",
        levelName: "",
        manureFertilizationPercentage: "",
        content: "",
      };
      this.$refs.form.resetFields();
    },
    submit() {
      this.$refs.form.validate().then((res) => {
        if (!res) return;
        let params = { ...this.form };
        params.manureFertilizationPercentage =
          this.form.manureFertilizationPercentage / 100;
        this.modal.submitLoading = true;
        this.$post(this.$api.AREA_LEVEL.ADD, params)
          .then(() => {
            this.getList();
            this.modal.show = false;
          })
          .finally(() => {
            this.modal.submitLoading = false;
          });
      });
    },
  },
  mounted() {
    this.getList();
  },
};
</script>

<style lang="less" scoped>
.area-level {
  width: 100%;
  height: 100%;
}
</style>